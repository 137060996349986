<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Basemaps" />
      <PageLayout>
        <v-data-table
          :headers="headers"
          :items="basemaps"
          hide-default-footer
          :search="search"
          disable-pagination
          class="py-0 my-0 cursor-pointer"
          single-select
          style="overflow-x: hidden !important"
        >
          <template v-slot:top>
            <div class="d-flex justify-space-between align-end gap px-2">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details="auto"
                color="primary"
                name="search"
              >
                <template v-slot:append>
                  <v-icon>{{ mdiMagnify }}</v-icon>
                </template>
              </v-text-field>

              <div>
                <v-btn
                  color="primary"
                  class="mt-4"
                  @click="showCreateBasemapDialog = true"
                  id="addBasemap"
                >
                  + Basemap
                </v-btn>
              </div>
            </div>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item of items"
                :key="item.basemap_id"
                :class="{ selectedRow: item === selectedBasemap }"
              >
                <td @click.stop="onBasemapRowClick(item)">{{ item.name }}</td>
                <td class="text-right">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" id="mapOptionsMenu">
                        <v-icon color="primary"> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-list id="mapOptions">
                      <v-list-item
                        class="cursor-pointer"
                        @click="onBasemapRowClick(item)"
                      >
                        <v-icon class="mr-1">
                          {{ mdiPencil }}
                        </v-icon>
                        Edit Basemap
                      </v-list-item>
                      <v-list-item
                        class="cursor-pointer"
                        @click="
                          showConfirmDeleteBasemapDialog = true;
                          basemapToDelete = item;
                        "
                      >
                        <v-icon class="mr-1">
                          {{ mdiDelete }}
                        </v-icon>
                        Delete Basemap
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <v-dialog
          v-model="showCreateBasemapDialog"
          max-width="600px"
          persistent
        >
          <CreateBasemapForm
            @create-basemap-form-close="showCreateBasemapDialog = false"
            @create-basemap-form-submitted="
              showCreateBasemapDialog = false;
              getBasemaps();
            "
          />
        </v-dialog>

        <v-dialog
          v-model="showEditBasemapDialog"
          max-width="600px"
          persistent
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <EditBasemapForm
            @edit-basemap-form-close="
              showEditBasemapDialog = false;
              $router.push({
                name: 'Basemaps',
                query: {},
              });
            "
            @edit-basemap-form-submitted="
              showEditBasemapDialog = false;
              getBasemaps();
              $router.push({
                name: 'Basemaps',
                query: {},
              });
            "
            :selectedBasemap="selectedBasemap"
          />
        </v-dialog>

        <v-dialog
          v-model="showConfirmDeleteBasemapDialog"
          max-width="600px"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
            <v-list-item>
              <v-list-item-content class="pl-3">
                <div class="text-h5 py-3">Delete Basemap?</div>
                Are you sure you want to delete this basemap? This action cannot
                be undone.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-end">
                  <v-btn
                    text
                    @click="
                      showConfirmDeleteBasemapDialog = false;
                      basemapToDelete = undefined;
                    "
                    color="primary"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="primary" @click="deleteBasemap">
                    Delete Basemap
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-dialog>
      </PageLayout>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import CreateBasemapForm from "@/components/basemap/CreateBasemapForm.vue";
import EditBasemapForm from "@/components/basemap/EditBasemapForm.vue";
import { mdiMagnify, mdiPencil, mdiDelete } from "@mdi/js";
import PageLayout from "@/components/layouts/PageLayout.vue";
import axios from "axios";
import usetifulMixin from "@/mixins/usetifulMixin";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Basemap Name",
    align: "start",
    sortable: false,
    value: "name",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "Basemap",
  components: {
    TopBar,
    CreateBasemapForm,
    EditBasemapForm,
    PageLayout,
  },
  mixins: [usetifulMixin],
  data() {
    return {
      search: "",
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      basemaps: [],
      showCreateBasemapDialog: false,
      showEditBasemapDialog: false,
      selectedBasemap: {},
      headers,
      showConfirmDeleteBasemapDialog: false,
      basemapToDelete: undefined,
    };
  },
  methods: {
    onBasemapRowClick(item) {
      this.showEditBasemapDialog = true;
      this.selectedBasemap = item;
      this.$router.push({
        name: "Basemaps",
        query: { basemap: this.selectedBasemap.basemap_id },
      });
    },
    async getBasemaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/basemaps`);
      this.basemaps = results;
    },
    async deleteBasemap() {
      if (!this.basemapToDelete) {
        return;
      }
      const { basemap_id: basemapId } = this.basemapToDelete;
      await axios.delete(`${APIURL}/basemaps/${basemapId}`);
      await this.getBasemaps();
      this.showConfirmDeleteBasemapDialog = false;
    },
  },
  async beforeMount() {
    await this.getBasemaps();
    if (this.$route.query?.basemap) {
      this.showEditBasemapDialog = true;
      this.selectedBasemap = this.basemaps.find(
        (m) => m.basemap_id === this.$route.query.basemap
      );
    }
  },
};
</script>

<style scoped>
.gap {
  gap: 15px;
}

.selectedRow {
  background-color: #f5f5f5;
}
</style>
