<template>
  <v-card class="m-2" style="background-color: #fafafa">
    <validation-observer ref="editBasemapForm" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title>Edit Basemap</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          class="px-8"
          :style="{
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
            'overflow-y': 'auto',
            'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
          }"
        >
          <v-card>
            <v-card-text>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Basemap Name"
                :rules="{ required: true, min: 1 }"
              >
                <v-text-field
                  v-model="basemap.name"
                  label="Name"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  id="basemapName"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Basemap Type"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  autocomplete="off"
                  v-model="basemap.type"
                  label="Type"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  id="basemapType"
                  :items="basemapTypes"
                  item-text="label"
                  item-value="value"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors, valid }"
                name="URL"
                :rules="{
                  required: true,
                  regex:
                    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                }"
                :bails="false"
              >
                <div class="d-flex flex-row">
                  <v-textarea
                    v-model="basemap.url"
                    label="URL"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="name"
                    id="basemapURL"
                    auto-grow
                    rows="1"
                  />
                  <v-btn
                    text
                    @click="goToService()"
                    width="30px"
                    min-width="30px"
                    height="30px"
                    min-height="30px"
                    class="mt-4"
                  >
                    <v-icon color="#1976d2">{{ mdiOpenInNew }}</v-icon>
                  </v-btn>
                </div>
              </validation-provider>
              <template v-if="tested">
                <p class="caption my-0 py-0" style="color: red" v-if="error">
                  {{ error }}
                </p>
                <p class="caption my-0 py-0" style="color: #093637" v-else>
                  Success! Connected to ArcGIS Feature Service.
                </p>
              </template>
              <validation-provider>
                <v-switch
                  class="py-0"
                  v-model="securedService"
                  label="Secured Service"
                >
                </v-switch>
              </validation-provider>
            </v-card-text>
            <v-card-actions class="d-flex justify-end py-0">
              <v-btn
                color="primary"
                class="mr-4 mt-n4 mb-2"
                @click="testUrl"
                :disabled="invalid"
              >
                Test Connection
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          type="submit"
          id="saveBasemap"
          :disabled="invalid"
          @click="submit()"
          >Save Basemap</v-btn
        ></v-card-actions
      >
    </validation-observer>
  </v-card>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import { EventBus } from "@/main.js";
import { mdiOpenInNew } from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

const basemapTypes = [
  { value: "T", label: "Tile Layer" },
  { value: "VT", label: "Vector Tile Layer" },
  { value: "WMS", label: "WMS Layer" },
];

export default {
  name: "EditBasemapForm",
  props: {
    selectedBasemap: Object,
  },
  data() {
    return {
      mdiOpenInNew,
      basemap: {},
      basemapTypes,
      securedService: false,
      tested: false,
    };
  },
  methods: {
    async submit() {
      await this.updateBasemap();
      this.$emit("edit-basemap-form-submitted");
    },
    async updateBasemap() {
      await axios.put(`${APIURL}/basemaps`, this.basemap);
    },
    async testUrl() {
      const success = await this.$refs.editBasemapForm.validate();
      if (!success) {
        return;
      }
      this.tested = false;
      this.error = "";
      const { basemap, securedService } = this;
      try {
        const url = new URL(basemap.url);
        const searchParams = new URLSearchParams();
        searchParams.append("f", "pjson");
        const token = securedService
          ? localStorage.getItem("esri_token")
          : undefined;
        if (token) {
          searchParams.append("token", token);
        }
        url.search = searchParams;
        const res = await fetch(url.toString(), {
          redirect: "manual",
        });
        const { type, status } = res;
        if (type === "opaqueredirect") {
          this.error =
            "Unable to connect to layer. Your user does not have permission to access that layer";
        } else {
          try {
            const data = await res.json();
            const error = data?.error?.message;
            if (error) {
              this.error = error;
            } else if (status >= 300) {
              this.error = `${status}: Unable to connect to layer, reason unknown.`;
            }
          } catch {
            const data = await res.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "text/html");
            const [error] = doc.getElementsByClassName("restErrors");
            if (error) {
              this.error = error?.textContent;
            } else if (status >= 300) {
              this.error = `${status}: Unable to connect to layer, reason unknown.`;
            }
          }
        }
      } catch (error) {
        this.error = "Unable to connect to layer, reason unknown.";
      } finally {
        this.tested = true;
      }

      EventBus.$emit("test-clicked", this.error);
    },
    goToService() {
      const token = localStorage.getItem("esri_token");
      if (this.securedService && token) {
        window.open(`${this.basemap.url}?token=${token}`, "blank");
      } else {
        window.open(this.basemap.url, "blank");
      }
    },
    closeDialog() {
      this.tested = false;
      this.$emit("edit-basemap-form-close");
    },
  },
  watch: {
    selectedBasemap: {
      deep: true,
      immediate: true,
      handler() {
        this.basemap = cloneDeep(this.selectedBasemap);
        if (this.basemap.token_type !== "NONE") {
          this.securedService = true;
        }
      },
    },
    securedService(val) {
      if (val) {
        this.basemap.token_type = "AGOL";
      } else {
        this.basemap.token_type = "NONE";
      }
    },
  },
};
</script>
