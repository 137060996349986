var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2", staticStyle: { "background-color": "#fafafa" } },
    [
      _c("validation-observer", {
        ref: "createBasemapForm",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.createBasemap.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary" },
                      },
                      [
                        _c("v-toolbar-title", [_vm._v("Create Basemap")]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: { click: _vm.closeDialog },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass: "px-8",
                        style: {
                          height: _vm.$vuetify.breakpoint.xsOnly
                            ? `${_vm.contentHeight}px`
                            : "auto",
                          "overflow-y": "auto",
                          "max-height": _vm.$vuetify.breakpoint.xsOnly
                            ? undefined
                            : "60vh",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Basemap Name",
                                    rules: { required: true, min: 1 },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Name",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                                id: "basemapName",
                                              },
                                              model: {
                                                value: _vm.basemap.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.basemap,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "basemap.name",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Basemap Type",
                                    rules: {
                                      required: true,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                autocomplete: "off",
                                                label: "Type",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                                id: "basemapType",
                                                items: _vm.basemapTypes,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value: _vm.basemap.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.basemap,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "basemap.type",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "URL",
                                    rules: {
                                      required: true,
                                      regex:
                                        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                                    },
                                    bails: false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row",
                                              },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    label: "URL",
                                                    "hide-details": "auto",
                                                    "error-messages": errors,
                                                    success: valid,
                                                    color: "primary",
                                                    name: "name",
                                                    id: "basemapURL",
                                                    "auto-grow": "",
                                                    rows: "1",
                                                  },
                                                  model: {
                                                    value: _vm.basemap.url,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.basemap,
                                                        "url",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "basemap.url",
                                                  },
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      text: "",
                                                      width: "30px",
                                                      "min-width": "30px",
                                                      height: "30px",
                                                      "min-height": "30px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.goToService()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "#1976d2",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.mdiOpenInNew
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.tested
                                  ? [
                                      _vm.error
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "caption my-0 py-0",
                                              staticStyle: { color: "red" },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.error) + " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass: "caption my-0 py-0",
                                              staticStyle: { color: "#093637" },
                                            },
                                            [
                                              _vm._v(
                                                " Success! Connected to ArcGIS Feature Service. "
                                              ),
                                            ]
                                          ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "validation-provider",
                                  [
                                    _c("v-switch", {
                                      staticClass: "py-0",
                                      attrs: { label: "Secured Service" },
                                      model: {
                                        value: _vm.securedService,
                                        callback: function ($$v) {
                                          _vm.securedService = $$v
                                        },
                                        expression: "securedService",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _c(
                              "v-card-actions",
                              { staticClass: "d-flex justify-end py-0" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4 mt-n4 mb-2",
                                    attrs: {
                                      color: "primary",
                                      disabled: invalid,
                                    },
                                    on: { click: _vm.testUrl },
                                  },
                                  [_vm._v(" Test Connection ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          type: "submit",
                          id: "createBasemap",
                          disabled: invalid,
                        },
                        on: { click: _vm.createBasemap },
                      },
                      [_vm._v("Create Basemap")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }